<template>
    <el-dialog :visible="visible" width="700px" @close="handleClose" class="dialog" title="设置">
        <el-row class="title">
            <el-form ref="form" :model="form" label-width="100px">
                <el-row :gutter="10">
                    <el-col :span="13">
                        <el-form-item label="任务标题：">
                            <el-input v-model="form.title" size="mini"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="完成日期：">
                            <el-date-picker
                                    v-model="form.date"
                                    type="date"
                                    size="mini"
                                    style="width: 100%"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="任务描述：">
                    <el-input
                            type="textarea"
                            :autosize="{ minRows: 4, maxRows: 6}"
                            placeholder="请输入内容"
                            v-model="form.textarea2">
                    </el-input>
                </el-form-item>
                <el-form-item label="" label-width="20px">
                    <el-checkbox v-model="form.checked">是否处理</el-checkbox>
                </el-form-item>
                <div class="display">
                    <el-divider content-position="left">信息列表</el-divider>
                    <div class="but">
                        <el-button type="success" size="mini">提交</el-button>
                        <el-button type="danger" size="mini">删除</el-button>
                        <el-button  size="mini">关闭</el-button>
                    </div>
                </div>
                <el-table
                        :data="tableData"
                        border
                        class="table"
                        size="mini"
                >
                    <el-table-column
                            label="标题"
                            type="index"
                            align="center"
                            width="70">
                    </el-table-column>
                    <el-table-column
                            prop="name"
                            label="日期"
                            align="center"
                            width="100">
                    </el-table-column>
                    <el-table-column
                            prop="money"
                            align="center"
                            label="描述"
                            width="">
                    </el-table-column>
                    <el-table-column
                            prop="money"
                            align="center"
                            label="是否处理"
                            width="">
                    </el-table-column>
                    <el-table-column
                            prop="money"
                            align="center"
                            label="检查医生"
                            width="">
                    </el-table-column>
                    <el-table-column
                            prop="money"
                            align="center"
                            label="检查日期"
                            width="">
                    </el-table-column>
                </el-table>
            </el-form>
        </el-row>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            visible: {
                default: false
            },
        },
        data() {
            return{
                form:{},
                tableData:[]
            }
        },
        methods: {
            async formSubmit() {
                const result = true //this.$refs['myforms'].validateHandle()
                if (result) {
                    try {
                        let url = `/devices/cvs/${this.form.id}`
                        const resp = await this.$http.put(url, this.form)
                        if (resp.data.status != 200) {
                            this.$message.error(resp.data.message)
                        } else {
                            this.$message.success(this.$t('device.success'))
                        }
                    } catch (e) {
                        this.$message.error(e.message)
                    }
                }
                this.$emit('update')
            },
            handleClose() {
                this.$emit('close')
            },
        }
    }
</script>

<style lang="scss" scoped>
    .display{
        display: flex;
        align-items: center;
    }
    .but{
        width: 50%;
    }
</style>
