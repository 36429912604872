<template>
    <div class="page" v-loading="loadingForm">
        <div class="header page-header">
            <div class="btns">
                <el-button size="mini" @click="$router.push({ path: `/main/phr/detail/1` })">
                    健康档案
                </el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="体检结论" />
        </div>
        <el-row style="border-top: 1px solid #EBEEF5" :gutter="20">
            <el-col :span="7" >
                <el-table :data="tableData" border class="table" size="mini" width="100%">
                    <el-table-column label="" type="index" align="center" width="50">
                    </el-table-column>
                    <el-table-column prop="name" label="体检项目" align="center" width="120">
                    </el-table-column>
                    <el-table-column prop="money" align="center" label="检查结果" width="">
                    </el-table-column>
                </el-table>
            </el-col>
            <el-col :span="17">
                <div class="main-form">
                    <el-row class="userinfo" style="margin-bottom: 15px;">
                        <el-col :span="8">检查：{{ report.ID }}</el-col>
                        <el-col :span="6">姓名：{{ report.XM }}</el-col>
                        <el-col :span="5">性别：{{ report.XB }}</el-col>
                        <el-col :span="5">年龄：{{ report.NL }}</el-col>
                    </el-row>
                    <el-form :model="form" label-width="100px" ref="form" size="mini">
                        <div class="tabs">
                            <el-tabs type="card">
                                <el-tab-pane label="检查汇总">
                                    <div class="height">
                                        <el-input v-model="form.JCJG"  type="textarea"
                                                  :autosize="{ minRows: 15, maxRows: 24 }"></el-input>
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane label="终检结论">
                                    <div class="height">
                                        <el-input v-model="form.ZJJL"  type="textarea"
                                                  :autosize="{ minRows: 15, maxRows: 24 }"></el-input>
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane label="健康促进建议">
                                    <div class="height">
                                        <el-input v-model="form.YLBJ" type="textarea"
                                                  :autosize="{ minRows: 15, maxRows: 24 }"></el-input>
                                    </div>
                                </el-tab-pane>
                            </el-tabs>
                        </div>
                    </el-form>
                    <div style="margin-top: 10px">
                        <el-button type="primary" @click="fuzheng" size="small">复诊</el-button>
                        <el-button type="primary" @click="zhonjian" size="small">终检结论</el-button>
                        <el-button type="warning" size="small" @click="handleMakeYLBJ"
                                   :loading="btn_anysis">生成健康促进建议</el-button>
                        <!-- <el-button type="warning" size="small">结论词</el-button>
                            <el-button type="warning" size="small">对比设置</el-button> -->
                        <el-button size="small" @click="addSubmit" :loading="but_lodging" :disabled="but_disabled">
                            确 定
                        </el-button>
                        <el-button size="small" @click="$router.push('/main/biopsy/index')">取 消</el-button>
                    </div>
                </div>
            </el-col>
        </el-row>
        <view-model :visible="viewModel" @close="viewModel = false" :form="report" ref="viewModel" />
        <fuzhen-form :visible="dialogVisible" :form="form" @close="dialogVisible = false"></fuzhen-form>
        <zhonjian-form :visible="modelZj" :form="form" @close="modelZj = false"
                       @updateZJJL="updateZJJL"></zhonjian-form>
    </div>
</template>

<script>
    import fuzhenForm from './widgets/fuzhen'
    import zhonjianForm from "./widgets/zhonjian"

    export default {
        name: 'biopsy-edit',
        path: '/main/biopsy/edit/:id',
        components: {
            fuzhenForm,
            zhonjianForm,
        },
        data() {
            return {
                pdfSrc: "https://cdn.zhack.com/daqing/test_report.pdf",
                but_lodging: false,
                but_disabled: false,
                btn_anysis: false,
                form: {
                    ID: '',
                    YLBJ: '',
                    JCJG: '',
                    ZJJL: ''
                },
                activeName: "",
                report: {},
                items: {},
                loadingForm: false,
                modelZj: false,
                dialogVisible: false,
                rules: {},
                viewModel: false,
                tableData: [
                    {
                        name: "体重指数",
                        money: "27.77",
                        sampling: "超重"
                    },
                    {
                        name: "收缩压偏高",
                        money: "143 mmHg",
                        sampling: ""
                    },
                    {
                        name: "总蛋白(TP)偏低",
                        money: "62.20",
                        sampling: ""
                    },
                    {
                        name: "球蛋白(GLO)偏低",
                        money: "19.2",
                        sampling: ""
                    }, {
                        name: "低密度脂蛋白(LDL)偏低",
                        money: "2.49",
                        sampling: ""
                    }, {
                        name: "乙肝表面抗体（HBsAb）",
                        money: "阳性",
                        sampling: ""
                    }, {
                        name: "甲状腺及颈部淋巴彩超",
                        money: "双侧颈部多发稍大淋巴结",
                        sampling: ""
                    },
                ],
            }
        },
        methods: {
            handleView() {
                this.viewModel = true
            },
            updateZJJL(ZJJL) {
                this.form.ZJJL = ZJJL
            },
            async handleMakeYLBJ() {
                try {
                    this.btn_anysis = true
                    const resp = await this.$http.post(`/biopsy/makeYLBJ`, this.form)
                    if (resp.data.status == 'success') {
                        this.form.YLBJ = ''
                        let index = 1
                        for (let q of resp.data.data) {
                            this.form.YLBJ += index + '. ' + q.ZJJL + ': ' + q.YLBJ + '\r\n\r\n'
                            index += 1
                        }
                    } else {
                        this.$message.error(resp.data.msg)
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
                this.btn_anysis = false
            },
            async addSubmit() {
                try {
                    this.loadingForm = true
                    const resp = await this.$http.post(`/biopsy/data`, this.form)
                    if (resp.data.status == 'success') {
                        this.$message.success(resp.data.msg)
                    } else {
                        this.$message.error(resp.data.msg)
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
                this.loadingForm = false
            },
            fuzheng() {
                this.dialogVisible = true
            },
            zhonjian() {
                this.modelZj = true
            },
            async getData() {
                try {
                    this.loadingForm = true
                    const form = await this.$http.get(`/biopsy/data/${this.$route.params.id}`)
                    console.log(form.data)
                    if (form.data.status == 'success') {
                        if (form.data.data) {
                            this.form = form.data.data
                        }
                    } else {
                        this.$message.error(form.data.message)
                    }
                    const resp = await this.$http.get(`/hss/report/view/${this.$route.params.id}`)
                    if (resp.data.status == 'success') {
                        this.report = resp.data.data
                        if (!this.form.JCJG && this.report.JL && this.report.JL.JCJG) {
                            this.form.JCJG = this.report.JL.JCJG
                            this.form.ZJJL = ''
                            this.form.YLBJ = ''
                        }
                        this.loadingForm = false
                    } else {
                        this.$message.error(resp.data.message)
                        this.loadingForm = false
                    }
                } catch (e) {
                    this.$message.error(e.message)
                    this.loadingForm = false
                }
            }
        },
        mounted() {
            // this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .page {
        height: 100%;
    }

    .header {
        padding: 15px;

        .btns {
            float: right;
        }
    }

    .main-form {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 150px);
    }

    .userinfo {
        height: 50px;
        line-height: 50px;
    }

    ::v-deep .el-tabs__header {
        padding: 0;
        position: relative;
        margin: 0 0 0px;
    }
</style>
